<template>
    <div class="home">
        <router-link to="/deliveries" class="is-pulled-right"><button class="button is-info is-small">Back to Deliveries </button></router-link><br /><br />
        
        <div class="box ">	
            <h4 class="title is-4">Order Details</h4>
            
            <div class="notification is-info" v-if="loading === true">
                <p>Loading order details ...</p>
            </div>
            
            <form @submit.prevent="updateDelivery" v-if="loading===false">
                <div class="field">
                          <label class="label">Supplier</label>
                          <div class="control">
                              <select class="input" v-model="delivery.supplier_id"  required >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in suppliers" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Description</label>
                          <div class="control">
                              <input class="input" type="text" v-model="delivery.description"  >
                             
                          </div>
                      </div>
      
                      <div class="field">
                          <label class="label">Estimated Delivery Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="delivery.estimated_delivery_date"  >
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Actual Delivery Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="delivery.actual_delivery_date"  >
                             
                          </div>
                      </div>
                

                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Update Order</button>
                          </div>
                      </div>
                  </form>
            
          </div>

          <div class="box">
            <router-link :to="'/batches/add/' + id"><button class="is-pulled-right button is-small is-success">Add New Batch</button></router-link>
            <h4 class="title is-4">Stones/Batches Assigned To Order</h4>

            <div class="notification is-info" v-if="loading === true">
                <p>Loading stones ...</p>
            </div>

            <table class="table is-fullwidth" v-if="loading === false">
                <thead>
                    <tr>
                        <th>Quantity</th>
                        <th>Stone Make</th>
                        <th>Stone Type</th>
                        <th>Length</th>
                        <th>Height</th>
                        <th>Thickness</th>
                        <th v-if="1==2">Photo</th>
                        <th width="50">&nbsp;</th>
                    </tr>

                </thead>
                <tbody>
                    <tr v-for="row in delivery.batches" :key="row.id">
                        <td>{{ row.qty}}</td>
                        <td>{{ row.stone_type_name}}</td>
                        <td>{{ row.stone_make_name}}</td>
                        <td>{{ row.length}}<span v-if="row.length != ''">mm</span></td>
                        <td>{{ row.height}}<span v-if="row.length != ''">mm</span></td>
                        <td>{{ row.thickness}}<span v-if="row.length != ''">mm</span></td>
                        <td  v-if="1==2"><img :src="'data:image/jpeg;base64,' + row.image_base64" style="height:20px" v-if="row.image_base64 != ''"> </td>
                        <td><router-link :to="'/batches/' + row.id"><button class="button is-link is-small">View</button></router-link></td>
                    </tr>
                    
                </tbody>
            </table>

          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewDelivery',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    delivery: {id: 0},
                    working:false,
                    suppliers: [],
                    id: this.$route.params.id
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getDelivery();
                this.getSuppliers();
              
            });
          
        },
        methods: {
           
            async getDelivery() {
                
                    StoneService.getDelivery(this.accessToken, this.$route.params.id)
                    .then(
                        (delivery => {
                             this.$set(this, "delivery", delivery);
                            
                        this.loading=false
                        }).bind(this)
                    );
                
            },
            async getSuppliers() {
                UtilityService.getSimpleApiData(this.accessToken, "suppliers").then(
                    response => this.suppliers = response 
                );
                
            },

            async updateDelivery() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    description : this.delivery.description,
                    supplier_id : this.delivery.supplier_id,
                    estimated_delivery_date : this.delivery.estimated_delivery_date,
                    actual_delivery_date : this.delivery.actual_delivery_date,
                    
                }; 

                StoneService.updateDelivery(this.accessToken, formdata, this.delivery.id)
                .then((response) => {
                    this.$router.push("/deliveries");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
           
        }
    }
    </script>
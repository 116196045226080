<template>
    <div>
        <div v-if="viewStone == false">
            <div class="field">
            <label class="label">Scan Your Barcode Below</label>
                <div class="control">
                    <form method="POST" @submit.prevent="scanStone()">
                    <input class="input" ref="focusMe" type="text" placeholder="Scan or type here" v-model="barcode" autofocus>
                    </form>
                </div>
            </div>
        </div>

        <div v-if="stone.id > 0 && loading == false" class="mt-4">
            <div class="card">
                    <header class="card-header">
                    <p class="card-header-title">
                    Stone Details - {{ stone.barcode }}
                    <button class="button is-small is-success ml-5" @click="showModal()" v-if="stone.in_use==false && stone.on_hold==false" :disabled="working==true">USE STONE</button>
                    <button class="button is-small is-danger ml-5" @click="showHoldModal()" v-if="stone.in_use==false && stone.on_hold==false" :disabled="working==true">SET ON HOLD</button>
                    <button class="button is-small is-warning ml-5"  v-if="stone.in_use==true">STONE ALREADY IN USE</button>
                    <button class="button is-small is-warning ml-5"  v-if="stone.on_hold==true">STONE ALREADY ON HOLD</button>
                    </p>
                    
                </header>
                <div class="card-content">
                    <div class="content">
                        <div class="columns">
                            <div class="column">
                                <h3 class="title">Stone</h3>
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td><strong>Type</strong></td>
                                        <td>{{ stone.stone_type_name}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Make</strong></td>
                                        <td>{{ stone.stone_make_name }}</td>
                                    </tr>
                                    <tr v-if="stone.in_use_note != ''">
                                        <td><strong>In Use</strong></td>
                                        <td>{{ stone.in_use_note }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Block</strong></td>
                                        <td>{{ stone.block }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Length</strong></td>
                                        <td>{{ stone.length }}mm</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Height</strong></td>
                                        <td>{{ stone.height }}mm</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Thickness</strong></td>
                                        <td>{{ stone.thickness }}mm</td>
                                    </tr>
                                    <tr v-if="stone.on_hold == 1">
                                        <td><strong>On Hold</strong></td>
                                        <td>{{ stone.on_hold_notes }}</td>
                                    </tr>
                                    <tr v-if="stone.in_use == 1">
                                        <td><strong>In Use</strong></td>
                                        <td>{{ stone.in_use_note }}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>

                            <div class="column" v-if="loading == false && stone.image != ''">
                                <h3 class="title">Photo</h3>
                                
                                <img :src="'data:image/jpeg;base64,' + stone.image_base64" style="width:100%" v-if="stone.image_base64 != '' && 1==2">
                                <img :src="API_NAME + '/batches/' + stone.batch_id + '/image'" style="width:100%" v-if="stone.image != '' " loading="lazy">                          
                                
                            
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <h3 class="title">Delivery Details</h3>
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td><strong>Supplier</strong></td>
                                        <td>{{ stone.supplier_name }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Estd. Delivery</strong></td>
                                        <td>{{ stone.estimated_delivery_date_formatted }}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Delivery Complete</strong></td>
                                        <td><span v-if="stone.received==1">Yes</span></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div class="column">
                                <h3 class="title">Job Details</h3>
                                <table class="table">
                                    <tbody>
                                    <tr>
                                        <td><strong>Name</strong></td>
                                        <td>{{ stone.job_name}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Start Date</strong></td>
                                        <td>{{ stone.job_start_date}}</td>
                                    </tr>
                                   
                                </tbody>
                                </table>
                            </div>
                           
                         </div>
                    </div>
                </div>
            </div>

            
            
        </div>

        <div class="notification is-danger mt-4" v-if="showError == true">
           <p>{{ errorMessage }}</p>
           
        </div>

        <div class="notification is-warning mt-4" v-if="loading == true">
           <p>Loading ...</p>
           
        </div>

        <div class="modal" :class="{'is-active': showInUseModal == true}">
            <div class="modal-background"></div>
                <div class="modal-content">
                    <!-- Any other Bulma elements you want -->
                    <div class="card">
                    <div class="card-content">
                        <div class="content">
                        <form @submit.prevent="setStoneInUse()">
                            <div class="field">
                                <label class="label">Add An "In Use" Note ?</label>
                                <div class="control">
                                    <textarea class="textarea" maxlength="500" type="text" placeholder="" rows="3" v-model="in_use_note"></textarea>
                                </div>
                            </div>

                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-success" >Save</button>
                                </div>
                               
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            <button class="modal-close is-large" aria-label="close" @click="hideModal()"></button>
        </div>

        <div class="modal" :class="{'is-active': showOnHoldModal == true}">
            <div class="modal-background"></div>
                <div class="modal-content">
                    <!-- Any other Bulma elements you want -->
                    <div class="card">
                    <div class="card-content">
                        <div class="content">
                        <form @submit.prevent="setStoneOnHold()">
                            <div class="field">
                                <label class="label">Add An "On Hold" Note ?</label>
                                <div class="control">
                                    <textarea class="textarea" maxlength="500" type="text" placeholder="" rows="3" v-model="on_hold_notes"></textarea>
                                </div>
                            </div>

                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-success" >Save</button>
                                </div>
                               
                            </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            <button class="modal-close is-large" aria-label="close" @click="hideModal()"></button>
        </div>

    </div>
</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ScanStone',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    
                     viewStone:false,
                     showError: false,
                     errorMessage : '',
                     stone: {id: 0},
                     barcode: '',
                     working: false,
                     showInUseModal: false,
                     showOnHoldModal: false,
                     in_use_note: '',
                     on_hold_notes: ''
                   
            }
        },
        methods: {
            async scanStone() {
                if(this.barcode != "")
                {
                    this.loading=true;
                    this.showError = false

                    StoneService.getStoneByBarcode(this.accessToken, this.barcode)
                    .then(
                        (stone => {
                        
                            this.$set(this, "stone", stone);
                            this.loading=false;
                            this.barcode = ''
                            this.focusInput()

                            if(Object.keys(stone).length === 0)
                            {
                                this.setError("A stone could not be found with that barcode")
                            }

                        }).bind(this)
                    );
                }
                else{
                    this.setError("Please enter a barcode and try again")
                }
            },
            async setStoneInUse() {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    in_use : 1,
                    in_use_note: this.in_use_note,
                    in_use_date : 'now()'
                }; 

                StoneService.setStoneInUse(this.accessToken, this.stone.id, formdata)
                .then(
                    (stone => {
                    
                        this.$set(this, "stone", stone);
                        this.working=false;
                        this.showInUseModal=false;
                        
                        if(Object.keys(stone).length === 0)
                        {
                            this.setError("The stone details could not be found, please scan again.")
                        }

                    }).bind(this)
                );
            },
            async setStoneOnHold() {
                this.working = true

                var formdata = { 
                    _method: 'patch',
                    on_hold : 1,
                    on_hold_notes: this.on_hold_notes,
                    on_hold_date : 'now()'
                }; 

                StoneService.setStoneInUse(this.accessToken, this.stone.id, formdata)
                .then(
                    (stone => {
                    
                        this.$set(this, "stone", stone);
                        this.working=false;
                        this.showOnHoldModal=false;
                        
                        if(Object.keys(stone).length === 0)
                        {
                            this.setError("The stone details could not be found, please scan again.")
                        }

                    }).bind(this)
                );
            },
            hideModal() {
                this.showInUseModal = false
                this.showOnHoldModal = false
            },
            showModal() {
                this.showInUseModal = true
            },
            showHoldModal() {
                this.showOnHoldModal = true
            },
            notWorking() {
                alert('Not yet working')
            },
            setError(message) {
                this.showError = true
                this.errorMessage = message
            },
            focusInput() {
                this.$nextTick(() => this.$refs.focusMe.focus())
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.focusInput()
            });
        
        },
        mounted () {
            this.focusInput()
        }
        
  
    }

</script>

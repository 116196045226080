<template>
    <div class="home">
        
          <div class="box">
            <h4 class="title is-4">Receive A Batch </h4>

            <div class="notification is-info" v-if="loading === true">
                <p>Loading batches ...</p>
            </div>
            
            <table class="table is-striped is-hoverable is-fullwidth" v-if="loading == false && showStones == false">
                <thead>
                    <tr>
                        <th>Stone Make</th>
                        <th>Qty</th>
                        <th>Barcodes Needed</th>
                        <th width="40"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in delivery.batches" :key="row.id">
                        <td>{{ row.stone_make_name}}</td>
                        <td>{{ row.qty }}</td>
                        <td>{{ barcodes_needed(row).length }}</td>
                        <td><button class="button is-small is-link" v-if="barcodes_needed(row).length > 0" @click="displayStones(row)">View</button></td>
                    </tr>
                </tbody>
            </table>
            
            <div class="content" v-if="loading == false && showStones == true">

                <p>Scan in the barcodes for each of the stones in this batch.</p>

                <div v-for="row in stonesList" :key="row.id" class="mb-4">
                    <span v-if="row.barcode != ''"><input  type="text" readonly disabled class="input is-small" :value="row.barcode" /></span>
                    
                    <form @submit.prevent="receiveStone(row)" v-if="row.barcode == ''">
                        <span ><input ref="focusMe" type="text" class="input is-small" v-model="row.barcode_input" required autofocus :disabled="working==true"/></span>
                    </form>

                    
                    
                </div>
                <p v-if="unreceived_stones.length == 0">All stones have now been barcoded in this batch</p>
                <button class="button  is-link"  @click="showStones=false">Return</button>
            </div>
            
            <div class="notification is-success" v-if="loading === false && showStones == false && total_unbarcoded_stones == 0">
                
                <form @submit.prevent="completeReceiveDelivery()" class="mt-4">
                    <button class="button  is-link">Complete Delivery</button>
                </form>
                
            </div>
                            
                        

          </div>

          <router-link to="/deliveries?app_view=1" class="is-pulled-right"><button class="button is-info is-small">Back to Orders </button></router-link><br /><br />
        
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewDelivery',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    delivery: {id: 0, stones: []},
                    working:false,
                    suppliers: [],
                    showStones: false,
                    stonesList: []
                    
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getDelivery();
                
            });
            
          
        },
        methods: {
           async receiveStone(stone) {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    barcode: stone.barcode_input
                    
                }; 

                StoneService.updateStone(this.accessToken, formdata, stone.id)
                .then(() => {
                    //console.log(response)
                        this.working = false
                        stone.barcode = stone.barcode_input

                        this.focusInput()
                    
                    }).catch((error) => {
                        if(error.response.status === 409)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data.error));
                            alert("Something went wrong.  \n\nError : " + error.response.data.error)
                            
                            stone.barcode_input = ""
                            this.focusInput()
                        }
                        else
                        {
                            alert("Something went wrong. Please try again or contact David.")
                        }
                        this.working = false
                });
                
           },   
            async getDelivery() {
                
                    StoneService.getDelivery(this.accessToken, this.$route.params.id)
                    .then(
                        (delivery => {
                             this.$set(this, "delivery", delivery);
                            this.loading=false
                            //this.focusInput()
                            
                        }).bind(this)
                    );
                
            },
            async completeReceiveDelivery() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    received:1
                    
                }; 

                StoneService.updateDelivery(this.accessToken, formdata, this.delivery.id)
                .then(() => {
                    this.$router.push("/deliveries?app_view=1");
                    //console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            
            focusInput() {
                this.$nextTick(() => {
                    //if(this.unreceived_stones > 0)
                   // {
                        this.$refs.focusMe[0].focus()
                        this.$refs.focusMe[0].scrollIntoView({ behavior: "smooth" })
                   // }
                });
            },
            barcodes_needed: function (row) {
               //console.log(row)
                 let barcodes_needed =  row.stones.filter(function(u) {
                    if(  u.barcode== null || u.barcode == '') 
                    {
                        return 1
                    }
                })
             return barcodes_needed
        
            },
            displayStones(row)
            {
                this.showStones = true
                this.stonesList = row.stones

                this.focusInput()
            }
           
        },
        computed : {
            unreceived_stones: function () {
            
                let unreceived_stones =  this.stonesList.filter(function(u) {
                    if(  u.barcode== null || u.barcode == '') 
                    {
                        return 1
                    }
                })
                return unreceived_stones
            
            },
            total_unbarcoded_stones: function() {
                const total = this.delivery.batches.reduce((sum, object) => {
                    let nobarcodes = this.barcodes_needed(object)

                    //CHANGE: add the values to the totals
                    sum += nobarcodes;
                    
                    return sum;
                }, 0) //CHANGE: Make the initial "sum" be the object with each key with a zero value

                //return array with summed values {dry:solution_cost_dry, wet:solution_cost_wet  }
                return total;
            }
            
            
        },
        watch: {
            
           
        }
    }
    </script>
<template>
    <div>
        <router-link to="/deliveries/add" v-if="app_view!=1"><button class="button is-success">Create a New Order</button></router-link><br /><br />

        <div class="notification is-info" v-if="loading === true">
            <p>Loading orders ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && deliveries.length == 0">
            <p>There are no orders to display</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && deliveries.length > 0">
            <thead>
                <tr>
                    <th>Supplier Name</th>
                    <th v-if="app_view!=1">Description</th>
                    <th>Estimated Delivery Date</th>
                    <th v-if="app_view!=1">Actual Delivery Date</th>
                    <th v-if="app_view!=1">Received</th>
                    <th width="50" v-if="1==2"></th>
                    <th width="50" v-if="app_view!=1"></th>
                    <th width="50" v-if="app_view==1"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in deliveries" :key="row.id">
                    <td>{{ row.supplier_name }}</td>
                    <td v-if="app_view!=1">{{ row.description }}</td>
                    <td>{{ row.estimated_delivery_date_formatted }}</td>
                    <td v-if="app_view!=1">{{ row.actual_delivery_date_formatted }}</td>
                    <td v-if="app_view!=1"><span v-if="row.received==1">Yes</span></td>
                    <td v-if="1==2"><button class="button is-small is-danger">Archive</button></td>
                    <td v-if="app_view!=1"><router-link :to="'/deliveries/' + row.id"><button class="button is-small is-link" >View</button></router-link></td>
                    <td v-if="app_view==1"><router-link :to="'/receive_delivery/' + row.id" v-if="row.batches.length>0"><button class="button is-small is-success" >Receive</button></router-link></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'Suppliers',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    deliveries: [],
                    app_view : this.$route.query.app_view
                   
            }
        },
        methods: {
            
            getDeliveries() {
                this.loading=true;
                StoneService.getDeliveries(this.accessToken, this.app_view)
                .then(
                    (deliveries => {
                    
                    this.$set(this, "deliveries", deliveries);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getDeliveries()
            });
        
        },
    }
</script>
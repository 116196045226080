<template>
    <div>
        <router-link to="/jobs/add"><button class="button is-success">Add New Job</button></router-link><br /><br />

        <div class="notification is-info" v-if="loading === true">
            <p>Loading jobs ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && jobs.length == 0">
            <p>There are no jobs to display</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && jobs.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Start Date</th>
                    <th width="50" v-if="1==2"></th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in jobs" :key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.description }}</td>
                    <td>{{ row.start_date_formatted }}</td>
                    <td v-if="1==2"><button class="button is-small is-danger">Archive</button></td>
                    <td><router-link :to="'/jobs/' + row.id"><button class="button is-small is-link" >View</button></router-link></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'Suppliers',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    jobs: []
                   
            }
        },
        methods: {
            
            getJobs() {
                this.loading=true;
                StoneService.getJobs(this.accessToken)
                .then(
                    (jobs => {
                    
                    this.$set(this, "jobs", jobs);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getJobs()
            });
        
        },
    }
</script>
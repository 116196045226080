<template>
    <div class="home">
        <router-link to="/stones" class="is-pulled-right"><button class="button is-info is-small">Back to Stones </button></router-link><br /><br />
        <div class="box">	
            <div class="notification is-info" v-if="loading === true">
                <p>Loading stone details ...</p>
            </div>
            
            <form @submit.prevent="updateStone" v-if="loading===false">
                <div class="field">
                          <label class="label">Barcode </label>
                          <div class="control">
                              <input class="input" type="text" v-model="stone.barcode" autofocus >
                             
                          </div>
                      </div>
      
                
                <div class="field">
                          <label class="label">Stone Make</label>
                          <div class="control">
                              <select class="input" v-model="stone.stone_make_id"  required>
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in stone_makes" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Associated Job</label>
                          <div class="control">
                              <select class="input" v-model="stone.job_id" >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in jobs" :key="row.id">{{ row.name }}</option>
                                
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Stone In Use</label>
                          <div class="control">
                              <select class="input" v-model="stone.in_use" >
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in in_use_values" :key="row.id">{{ row.name }}</option>
                                
                            </select>
                             
                          </div>
                      </div>

                     

                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working==true">Update Stone</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'ViewStone',
        mixins: [],
        data: function () {
            return {
                   loading : true,
                   working: false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    jobs: [],
                    stone_types: [],
                    stone_makes: [],
                    stone: {id: 0},
                    in_use_values : [{'id': 0, 'name': 'No'}, {'id': 1, 'name': 'Yes'}],
                    deliveries: []
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
                this.getStone();
               this.getJobs();
               this.getStoneTypes();
               this.getStoneMakes();
               this.getDeliveries();
            });
          
        },
        methods: {
            async getJobs() {
                UtilityService.getSimpleApiData(this.accessToken, "jobs").then(
                    response => this.jobs = response 
                );
                
            },
            async getStoneTypes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_types").then(
                    response => this.stone_types = response 
                );
                
            },
            async getStoneMakes() {
                UtilityService.getSimpleApiData(this.accessToken, "stone_makes").then(
                    response => this.stone_makes = response 
                );
                
            },
            async getDeliveries() {
                UtilityService.getSimpleApiData(this.accessToken, "deliveries").then(
                    response => this.deliveries = response 
                );
                
            },
            async getStone() {
                
                    StoneService.getStone(this.accessToken, this.$route.params.id)
                    .then(
                        (stone => {
                             this.$set(this, "stone", stone);
                            
                        this.loading=false
                        }).bind(this)
                    );
                
            },

            async updateStone() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    barcode : this.stone.barcode,
                    stone_make_id : this.stone.stone_make_id,
                    job_id : this.stone.job_id,
                     in_use : this.stone.in_use
                    
                }; 

                StoneService.updateStone(this.accessToken, formdata, this.stone.id)
                .then((response) => {
                    this.$router.push("/stones");
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 409)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data.error));
                            alert("Something went wrong.  \n\nError : " + error.response.data.error)
                        }
                        else
                        {
                            alert("Something went wrong. Please try again or contact David.")
                        }
                        this.working = false
                });
            },
           
        }
    }
    </script>
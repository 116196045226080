<template>
    <div>
        <div class="is-pulled-left" v-if="1==2"><router-link to="/stones/add"><button class="button is-success">Add New Stone(s) </button></router-link></div>
        
       
        <div class="is-pulled-right">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>
        <div class="is-pulled-right" v-if="1==2">
            
        </div>
        <br /><br />
        <table class="table is-fullwidth is-striped is-hoverable is-size-7">
            <thead>
                <tr>
                    <th>Stone Make</th>
                    <th>Stone Type</th>
                    <th>Barcode</th>
                    <th>Job Name</th>
                    <th>Received</th>
                    <th>In Use / Hold</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_stones" :key="row.id">
                    <td>{{ row.stone_make_name }}</td>
                    <td>{{ row.stone_type_name }}</td>
                    <td>{{ row.barcode }}</td>
                    <td>{{ row.job_name }}</td>
                    <td></td>
                    <td>
                        <span class="tag is-success" v-if="row.in_use == true" >In Use</span> <span v-if="row.in_use == true">{{ row.in_use_note }}</span>
                        <span class="tag is-danger" v-if="row.on_hold == true" >On Hold</span> <span v-if="row.on_hold == true">{{ row.on_hold_notes}}</span>
                    </td>
                    <td>
                        <router-link :to="'/stones/' + row.id"><button class="button is-small is-link">View</button></router-link>
                    </td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'Stones',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stones: []
                   
            }
        },
        methods: {
            
            getStones() {
                this.loading=true;
                StoneService.getStones(this.accessToken)
                .then(
                    (stones => {
                    
                    this.$set(this, "stones", stones);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStones()
            });
        
        },
        computed : {
            filtered_stones: function () {
                let stone_search = this.search

                let filtered_stones =  this.stones.filter(function(u) {
                    if(  u.active == 1) // u.artwork_new == 0 && u.artwork_repeat == 0 &&
                    {
                        return 1
                    }
                })

                if(stone_search != '' && stone_search != null)
                {
                    filtered_stones = filtered_stones.filter(row => row.in_use_note.toLowerCase().includes(stone_search.toLowerCase()) || 
                        ( row.on_hold_notes != null && row.on_hold_notes.toLowerCase().includes(stone_search.toLowerCase()) ) || 
                        ( row.job_name != null && row.job_name.toLowerCase().includes(stone_search.toLowerCase())  ) || 
                        ( row.stone_make_name != null && row.stone_make_name.toLowerCase().includes(stone_search.toLowerCase())  )  ||
                        ( row.stone_type_name != null && row.stone_type_name.toLowerCase().includes(stone_search.toLowerCase())  )  
                    )
                }

                return filtered_stones
            
            },
            
        },
    }
</script>
<template>
    <div class="home">
        <router-link to="/deliveries" class="is-pulled-right"><button class="button is-info is-small">Back to Orders </button></router-link><br /><br />
        <div class="box">	
              
            
            <form @submit.prevent="saveDelivery">
                
                <div class="field">
                          <label class="label">Supplier</label>
                          <div class="control">
                              <select class="input" v-model="supplier_id"  required autofocus>
                                <option value="">Please select ...</option>
                                <option :value="row.id" v-for="row in suppliers" :key="row.id">{{ row.name }}</option>
                               
                            </select>
                             
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Description </label>
                          <div class="control">
                              <input class="input" type="text" v-model="description"  >
                             
                          </div>
                      </div>
      
                      <div class="field">
                          <label class="label">Estimated Delivery Date </label>
                          <div class="control">
                              <input class="input" type="date" v-model="estimated_delivery_date"  >
                             
                          </div>
                      </div>
      
                      <div class="field">
                          <label class="label">Actual Delivery Date </label>
                          <div class="control">
                              <input class="input" type="date" v-model="actual_delivery_date"  >
                             
                          </div>
                      </div>
      
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Save Order</button>
                          </div>
                      </div>
                  </form>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import StoneService from '@/services/StoneService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddStone',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    suppliers: [],
                    description: '',
                    supplier_id: '',
                    estimated_delivery_date: this.getStartDate(),
                    actual_delivery_date : '',
                    working:false
                    
                    
                   
            }
        },
        created() {
          this.getAccessToken()
            .then(() => { 
               this.getSuppliers();
            });
          
        },
        methods: {
            
            async getSuppliers() {
                UtilityService.getSimpleApiData(this.accessToken, "suppliers").then(
                    response => this.suppliers = response 
                );
                
            },

            async saveDelivery() {
                this.working = true
                var formdata = { 
                    description : this.description,
                    supplier_id : this.supplier_id,
                    estimated_delivery_date : this.estimated_delivery_date,
                    actual_delivery_date : this.actual_delivery_date,
                }; 

                StoneService.saveDelivery(this.accessToken, formdata)
                .then((response) => {
                    this.$router.push("/deliveries/" + response.id);
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
            getStartDate() {
            
                var d = new Date();
                
                d.setDate(d.getDate() + 7);
                return d.toISOString().slice(0,10);
                
            },
           
        }
    }
    </script>